define("empress-blog/controllers/index", ["exports", "@ember/controller", "@ember/service", "@ember/object", "@ember/template"], function (_exports, _controller, _service, _object, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* eslint-disable ember/require-computed-property-dependencies, ember/no-get */
  var _default = _controller.default.extend({
    blog: (0, _service.inject)(),
    url: (0, _service.inject)(),
    queryParams: Object.freeze(['page']),
    page: '/content/content-0.json',
    coverImageStyle: (0, _object.computed)('blog.coverImage', function () {
      return (0, _template.htmlSafe)(`background-image: url(${(0, _object.get)(this, 'url.prefix')}${(0, _object.get)(this, 'blog.coverImage')})`);
    }),
    pageNumber: (0, _object.computed)('page', function () {
      let page = parseInt(this.page.match(/content\/content-(.*).json/)[1]);
      return page + 1;
    }),
    numerOfPages: (0, _object.computed)('model.links.last', function () {
      let pages = parseInt(this.model.links.last.match(/content\/content-(.*).json/)[1]);
      return pages + 1;
    })
  });

  _exports.default = _default;
});