define("empress-blog/routes/tag", ["exports", "@ember/routing/route"], function (_exports, _route) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend({
    classNames: ["tag-template"],

    model(params) {
      // load content first for ember-data autopopulation
      return this.store.findAll('content').then(() => {
        return this.store.findRecord('tag', params.id);
      });
    }

  });

  _exports.default = _default;
});