define("ember-intl/translations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [["en-us", {
    "buttons": {
      "details": "More details"
    },
    "contacts": {
      "email": {
        "alt": "Email icon",
        "title": "sebastien@devedanos.com"
      },
      "location": {
        "alt": "location pin icon",
        "title": "4 Avenue Laurent Cély, Chez BVTC CONSEIL, 92606 ASNIÈRES-SUR-SEINE CEDEX, FRANCE"
      },
      "phone": {
        "alt": "Phone icon",
        "title": "+33780988325"
      },
      "title": "Contacts",
      "vat": {
        "alt": "Handshake icon",
        "international": "International VAT number: FR94910383116",
        "siret": "French company ID: 910 383 116 00019 R.C.S NANTERRE"
      }
    },
    "credits": {
      "by": "by Sébastien NOBOUR © Devedanos - 2023",
      "developed": "Developed with",
      "empress": "Empress",
      "source-code": "Website source code"
    },
    "devedanos": "Devedanos",
    "devedanos-logo": "Devedanos logo; Devedanos: Web development",
    "forms": {
      "contact": {
        "email": {
          "label": "Email address",
          "placeholder": "ex: sebastien@devedanos.com"
        },
        "full-name": {
          "label": "Full name",
          "placeholder": "ex: Sébastien NOBOUR - Devedanos"
        },
        "message": {
          "label": "Message",
          "placeholder": "Write your message here"
        },
        "submit": {
          "title": "Make an appointment!"
        }
      }
    },
    "images": {
      "flags": {
        "en-us": {
          "alt": "USA flag"
        },
        "fr-fr": {
          "alt": "French flag"
        }
      }
    },
    "languages": {
      "list": {
        "en-us": "English",
        "fr-fr": "Français"
      }
    },
    "links": {
      "home": "Home",
      "home-logo": "Devedanos logo. Click on it to return to home page",
      "legal-notices": "Legal notices",
      "resume": "Resume"
    },
    "logo": "logo",
    "messages": {
      "download-pdf": "Click here to download the PDF file",
      "no-pdf-reader": "It seems that your web browser does not have a PDF reader."
    },
    "navbar": {
      "nav-main": {
        "button-mobile-menu": {
          "description": "Open main menu"
        },
        "contact": {
          "text": "Make an appointment!"
        },
        "language-picker": {
          "aria-label": "English, Select your language",
          "button-image": {
            "alt": "{language} language is currently active."
          },
          "description": "Select your language"
        }
      }
    },
    "pages": {
      "home": {
        "banners": {
          "about-me": {
            "line-1": "I started web development in September 2018. I graduated as an application developer of applications since 2020. Computer science is a passion and I always wanted to become a developer/programmer.",
            "line-2": "I am also an entrepreneur. My mindest ? Client first and data-driven decisions, I strive to be constructive in what I say and provide a great user experience.",
            "line-3": "I am specialized on the Javascript programming language and prefer to use its backend framework Node.js instead of PHP, although I was also a PHP developer (with Symfony, API-Platform or CMS Wordpress)",
            "line-4": "And to know more, here is my resume",
            "title": "About me"
          },
          "creations": {
            "card": {
              "mentoring": {
                "alt": "A web developer helped by his coach.",
                "description": "Junior or student web developer? Book your 1 hour free mentoring!"
              },
              "nobour-eshop": {
                "alt": "nobour-eshop.com screenshot",
                "description": "E-commerce SPA and REST API using MERN stack, Docker, and deployed on AWS through CI/CD."
              },
              "sym-bnb": {
                "alt": "Sym5bnb screenshot",
                "description": "MVC web application built with Symfony 5 and replicating AirBnB."
              },
              "sym-react-crm": {
                "alt": "Sym-react-crm screenshot",
                "description": "SPA and REST API for CRM application made with API-Platform and React."
              },
              "weekandgo": {
                "alt": "capture d'écran de www.weekandgo.com",
                "description": "Niche marketplace for recreational vehicles. Next.js, API Platform, Strapi headless CMS."
              }
            },
            "title": "Here are some of my realisations"
          },
          "cta": {
            "title": "I am looking for a freelance developer to bring my project to life and complete my team!"
          },
          "process": {
            "line-1": "After analyzing the description of your project, if I can accompany you, I will send you an email  so that we can set up a physical meeting or a videoconference with your tools or mine (probably more  adapted since I live near Maastricht, Netherlands).",
            "line-2": "During the meeting, we will try to get to know each other better, and then to precisely identify the  goals of your project in order to understand or even advise on the functionalities to be developed,  how to organize their development, which technologies to use and have a first idea of the visual style for  the application if no specifications book exists.",
            "line-3": "At the end of this meeting, we will determine the next step to take according to the situation.  We will be able to decide whether fixed-price or time-based billing is more appropriate for your needs.  If no functional and technical specifications exist beforehand, I will propose you elaborate  it together (legally required, even with Agile methodology).",
            "title": "'What happens after I contact you through the form?'"
          },
          "services": {
            "card": {
              "backend": {
                "alt": "Binary data processing image",
                "description": "Development of REST or GraphQL or JSON APIs with Nest.js or Express.js with SQL or NoSQL databases"
              },
              "devops": {
                "alt": "Image of a cloud",
                "description": "Deployment of SPAs and APIs with CI/CD pipelines and Docker containers on Amazon Web Services"
              },
              "frontend": {
                "alt": "User Interface image",
                "description": "Development of responsive Single Page Applications with React.js or Next.js frameworks for better SEO and UX."
              },
              "maintenance": {
                "alt": "Image of boxes representing the packages/software to be maintained running the application",
                "description": "Corrective, minor and major updates to ensure the longevity and security of the application."
              },
              "mentoring": {
                "alt": "A frame for a painting.",
                "description": "Mentoring for junior and student web developer"
              },
              "testing": {
                "alt": "Image of a solid brick of walls where every layer of brick is made sure being stable before adding the upcoming layer.",
                "description": "Automated testing (unit, interactive, visual, application, system) insuring the stability of existing features."
              }
            },
            "introduction": "Hello! I am Sébastien NOBOUR, freelance French web application developer, full-remote working, specialized on Node.js and Javascript.",
            "title": "And this is how I can help you"
          },
          "usp": {
            "image": {
              "description": "Image with view of a sunset, clouds and mountain peaks below"
            },
            "title": "I automate manual tasks by developing tailor-made software to help companies focus on high value-added tasks and generate greater profits."
          }
        },
        "title": "Devedanos | Home"
      },
      "legal-notices": {
        "paragraph-1": "Identification of the publisher and host of the site",
        "paragraph-2": "The website www.devedanos.com is edited by S NOBOUR SASU, a company registered at the RCS of NANTERRE under the number 910 383 116, whose head office is located at 4 AVENUE LAURENT CÉLY, 92606 ASNIÈRES-SUR-SEINE CEDEX, FRANCE.",
        "paragraph-3": "International VAT number: FR94910383116.",
        "paragraph-4": "Director of the publication : Sébastien NOBOUR, CEO of S NOBOUR SASU, reachable at +33780988325 or at sebastien@devedanos.com.",
        "paragraph-5": "The site is hosted by Amazon Web Services, Inc, 410 Terry Ave North, Seattle , WA 98109-5210 , US. Information regarding the collection and processing of personal data (policy and statement) is provided in the site's personal data policy.",
        "paragraph-6": "All rights reserved - January 1, 2023",
        "title": "Devedanos | Legal Notices"
      },
      "resume": {
        "cv-aria-label": "PDF resume",
        "title": "Devedanos | Resume"
      }
    },
    "quick-links": {
      "title": "Quick links"
    },
    "social-medias": {
      "links": {
        "facebook": {
          "alt": "Facebook logo"
        },
        "instagram": {
          "alt": "Instagram logo"
        },
        "linked-in": {
          "alt": "Linked-in logo"
        },
        "twitter": {
          "alt": "Twitter logo"
        }
      },
      "title": "Let's keep in touch!"
    }
  }], ["fr-fr", {
    "buttons": {
      "details": "Plus de détails"
    },
    "contacts": {
      "email": {
        "alt": "Icône d'email",
        "title": "sebastien@devedanos.com"
      },
      "location": {
        "alt": "Îcone d'un pointeur de localisation",
        "title": "4 Avenue Laurent Cély, Chez BVTC CONSEIL, 92606 ASNIÈRES-SUR-SEINE CEDEX, FRANCE"
      },
      "phone": {
        "alt": "Icône de téléphone",
        "title": "+33780988325"
      },
      "title": "Coordonnées",
      "vat": {
        "alt": "Icône d'une poignée de main",
        "international": "N° de TVA Intracommunautaire: FR94910383116",
        "siret": "SIRET français: 910 383 116 00019 R.C.S NANTERRE"
      }
    },
    "credits": {
      "by": "par Sébastien NOBOUR © Devedanos - 2023",
      "developed": "Developpé avec",
      "empress": "Empress",
      "source-code": "Code source du site"
    },
    "devedanos": "Devedanos",
    "devedanos-logo": "Logo de Devedanos: Devedanos: Développement web",
    "footer": null,
    "forms": {
      "contact": {
        "email": {
          "label": "Adresse email",
          "placeholder": "ex: sebastien@devedanos.com"
        },
        "full-name": {
          "label": "Nom complet",
          "placeholder": "ex: Sébastien NOBOUR - Devedanos"
        },
        "message": {
          "label": "Message",
          "placeholder": "Écrivez votre message ici"
        },
        "submit": {
          "title": "Prendre RDV!"
        }
      }
    },
    "images": {
      "flags": {
        "en-us": {
          "alt": "Drapeau des USA"
        },
        "fr-fr": {
          "alt": "Drapeau de France"
        }
      }
    },
    "languages": {
      "list": {
        "en-us": "English",
        "fr-fr": "Français"
      }
    },
    "links": {
      "home": "Accueil",
      "home-logo": "Logo de Devedanos. Cliquez dessus pour retourner en page d'accueil",
      "legal-notices": "Mentions légales",
      "resume": "CV"
    },
    "logo": "logo",
    "messages": {
      "download-pdf": "Cliquez ici pour télécharger le fichier PDF.",
      "no-pdf-reader": "Il semble que votre navigateur web ne possède pas de liseuse de PDF."
    },
    "navbar": {
      "nav-main": {
        "button-mobile-menu": {
          "description": "Ouvrir le menu principal"
        },
        "contact": {
          "text": "Prendre RDV!"
        },
        "language-picker": {
          "aria-label": "Français, choisissez votre langue",
          "button-image": {
            "alt": "{language} est la langue actuellement active."
          },
          "description": "Choisissez votre langue"
        }
      }
    },
    "pages": {
      "home": {
        "banners": {
          "about-me": {
            "line-1": "J'ai commencé le développement web en septembre 2018. Je suis diplômé développeur-concepteur d'applications depuis 2020. L'informatique est une passion et j'ai toujours voulu devenir développeur/programmeur.",
            "line-2": "Je suis également un entrepreneur. Mon mindset ? 'Client-first' et 'data-driven' dans mes décisions, je m'efforce d'être constructif dans mes propos et faire vivre une excellente expérience utilisateur.",
            "line-3": "Je suis spécialisé sur le langage de programmation Javascript et préfère utiliser son framework backend Node.js au lieu de PHP, bien que j'ai aussi été développeur PHP (avec les frameworks Symfony, API-Platform ou CMS Wordpress).",
            "line-4": "Et pour en savoir plus, voici mon CV.",
            "title": "À propos de moi"
          },
          "creations": {
            "card": {
              "mentoring": {
                "alt": "Un cadre pour une peinture.",
                "description": "Développeur junior ou étudiant&nbsp;? Réservez votre heure gratuite de mentoring !"
              },
              "nobour-eshop": {
                "alt": "capture d'écran de www.nobour-eshop.com",
                "description": "SPA et API REST e-commerce en MERN stack et Docker, déployée sur AWS via CI/CD."
              },
              "sym-bnb": {
                "alt": "capture d'écran de sym-5-bnb",
                "description": "Application web MVC développée en Symfony 5 répliquant AirBnB."
              },
              "sym-react-crm": {
                "alt": "capture d'écran de sym-react-crm",
                "description": "SPA et API REST pour un CRM en API-Platform et React."
              },
              "weekandgo": {
                "alt": "capture d'écran de www.weekandgo.com",
                "description": "Marketplace de niche pour véhicules de loisirs. Next.js, API Platform, et CMS headless en Strapi."
              }
            },
            "title": "Voici quelques-unes de mes réalisations"
          },
          "cta": {
            "title": "Je cherche un développeur freelance pour donner vie à mon mon projet et compléter mon équipe !"
          },
          "process": {
            "line-1": "Après avoir analysé la description de votre projet, si je peux vous accompagner, je vous enverrai  un email pour que nous fixions un rendez-vous physique ou en visio-conférence avec vos outils ou les miens (probablement plus adapté puisque je vis vers Maastricht, Pays-Bas).",
            "line-2": "Au cours du rendez-vous, nous chercherons à mieux nous connaître, puis à cerner précisément  les objectifs de votre projet afin de comprendre voire conseiller sur les fonctionnalités à développer,  comment organiser leurs développements, quelles technologies utiliser  et avoir une première idée du  style visuel de l'application si aucun cahier des charges n'existe.",
            "line-3": "À la fin de ce rendez-vous, nous déterminerons la suite à donner en fonction de la situation.  Nous saurons apte à choisir si la facturation au forfait ou en régie est plus adaptée à votre besoin.  Si aucun cahier des charges fonctionnel et technique n'existe au préalable, je vous proposerai de l'élaborer  ensemble (obligatoire légalement, même em méthodologie Agile).",
            "title": "'Que se passe-t-il une fois que je vous ai contacté via le formulaire ?'"
          },
          "services": {
            "card": {
              "backend": {
                "alt": "Image de traitement d'informations binaires",
                "description": "Développement d'APIs REST ou GraphQL ou JSON API avec Nest.js ou Express.js, avec bases de données SQL ou NoSQL"
              },
              "devops": {
                "alt": "Image d'un nuage",
                "description": "Deploiement de SPAs and APIs via pipelines CI/CD et containers Docker dans le Cloud Amazon Web Services"
              },
              "frontend": {
                "alt": "Image d'interface utilisateur",
                "description": "Développement de Single Page Applications responsive via les frameworks React.js ou Next.js pour de meilleurs SEO et UX."
              },
              "maintenance": {
                "alt": "Image de cartons représentants les packages/mini-logicielles à maintenir faisant fonctionner l'application",
                "description": "Mises à jour correctives, mineures et majeures afin d'assurer les longévité et sécurité de l'application."
              },
              "mentoring": {
                "alt": "Un développeur web aidé par son coach.",
                "description": "Mentoring pour développeur web junior et étudiant."
              },
              "testing": {
                "alt": "Image d'un mur en briques solides où chaque couche de briques est assurée d'être stable avant d'ajouter la couche suivante.",
                "description": "Tests automatisés (unitaires, interactifs, visuels, application, système) assurant la stabilité des fonctionnalités existantes."
              }
            },
            "introduction": "Bonjour ! Je suis Sébastien NOBOUR, développeur freelance français d'applications web, opérant à 100% en télétravail, spécialisé sur Node.js et Javascript.",
            "title": "Voici comment je peux vous aider"
          },
          "usp": {
            "image": {
              "description": "Image avec vue sur un coucher de soleil, des nuages et sommets de montagnes en contrebas"
            },
            "title": "J'automatise les tâches manuelles en développant des logiciels sur mesure pour aider les entreprises à se concentrer sur les tâches à forte valeur ajoutée et à générer plus de profits."
          }
        },
        "title": "Devedanos | Accueil"
      },
      "legal-notices": {
        "paragraph-1": "Identification de l'éditeur et de l'hébergeur du site",
        "paragraph-2": "Le site www.devedanos.com est édité par S NOBOUR SASU, entreprise immatriculée au RCS de NANTERRE sous le numéro 910 383 116, dont le siège social est sis au 4 AVENUE LAURENT CÉLY, 92606 ASNIÈRES-SUR-SEINE CEDEX, FRANCE.",
        "paragraph-3": "N° de TVA intracommunautaire : FR94910383116.",
        "paragraph-4": "Directeur de la publication : Sébastien NOBOUR, Dirigeant de S NOBOUR SASU, joignable au +33780988325 ou à l'adresse sebastien@devedanos.com.",
        "paragraph-5": "Le site est hébergé par Amazon Web Services, Inc., 410 Terry Ave North, Seattle , WA 98109-5210 , US. Les informations concernant la collecte et le traitement des données personnelles (politique et déclaration) sont fournies dans la charte de données personnelles du site.",
        "paragraph-6": "Tous droits réservés - 1er janvier 2023",
        "title": "Devedanos | Mentions Légales"
      },
      "resume": {
        "cv-aria-label": "CV en PDF",
        "title": "Devedanos | CV"
      }
    },
    "quick-links": {
      "title": "Liens rapides"
    },
    "social-medias": {
      "links": {
        "facebook": {
          "alt": "Logo de Facebook"
        },
        "instagram": {
          "alt": "Logo d'Instagram"
        },
        "linked-in": {
          "alt": "Logo de Linked-in"
        },
        "twitter": {
          "alt": "Logo de Twitter"
        }
      },
      "title": "Gardons contact !"
    }
  }]];
  _exports.default = _default;
});