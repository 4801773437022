define("ember-scroll/services/scroll", ["exports", "@ember/service", "@ember/object", "@ember/runloop", "@ember/object/internals"], function (_exports, _service, _object, _runloop, _internals) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ScrollService = (_class = class ScrollService extends _service.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "router", _descriptor, this);

      _defineProperty(this, "guid", `${(0, _internals.guidFor)(this)}-scroll-target`);

      _defineProperty(this, "doScroll", true);

      _defineProperty(this, "_hasSetupElement", false);

      if (window.addEventListener) {
        window.addEventListener('popstate', this.handlePopstate);
      }
    }

    handlePopstate() {
      // we want the popstate event to be handled in between routeWillChange and routeDidChange
      (0, _runloop.next)(() => {
        this.doScroll = false;
      });
    }

    routeWillChange() {
      this.doScroll = true;
    }

    routeDidChange(transition) {
      // don't mess with the scroll there is no from in the route transition (meaning its the initial load)
      if (!transition.from) {
        this.doScroll = true;
        return;
      } // we want this to happen after the popstate event is handled


      (0, _runloop.next)(() => {
        (0, _runloop.scheduleOnce)('afterRender', this.scrollUp);
      });
    }

    scrollUp() {
      if (this.doScroll) {
        this._setupElement();

        document.getElementById(this.guid).focus();
        window.scrollTo(0, 0);
      }

      this.doScroll = true;
    }

    _setupElement() {
      if (this._hasSetupElement) {
        return;
      }

      const element = document.createElement('div');
      const text = document.createTextNode('The page navigation is complete. You may now navigate the page content as you wish.');
      element.append(text);
      element.setAttribute('id', this.guid);
      element.setAttribute('class', 'ember-scroll-navigation-message');
      element.setAttribute('tabindex', -1);
      element.setAttribute('role', 'text');
      document.body.prepend(element);
      this._hasSetupElement = true;
    }

    willDestroy() {
      if (window.removeEventListener) {
        window.removeEventListener('popstate', this.handlePopstate);
      }

      const element = document.getElementById(this.guid);

      if (element) {
        element.remove();
      }

      super.willDestroy();
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "handlePopstate", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handlePopstate"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "routeWillChange", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "routeWillChange"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "routeDidChange", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "routeDidChange"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "scrollUp", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "scrollUp"), _class.prototype)), _class);
  _exports.default = ScrollService;
});