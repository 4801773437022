define("empress-blog/services/head-data", ["exports", "ember-meta/services/head-data", "@ember/application", "@ember/object", "ember-get-config", "empress-blog/helpers/excerpt"], function (_exports, _headData, _application, _object, _emberGetConfig, _excerpt) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _class;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const {
    blog
  } = _emberGetConfig.default;
  let HeadDataService = (_dec = (0, _object.computed)('config.title'), _dec2 = (0, _object.computed)('routeName'), _dec3 = (0, _object.computed)('currentController.model.post'), _dec4 = (0, _object.computed)('currentRouteMeta.name', 'routeName'), _dec5 = (0, _object.computed)('currentRouteMeta'), _dec6 = (0, _object.computed)('currentRouteMeta.id'), _dec7 = (0, _object.computed)('currentRouteMeta.tags'), _dec8 = (0, _object.computed)('currentRouteMeta.image'), _dec9 = (0, _object.computed)('router.currentURL'), _dec10 = (0, _object.computed)('routeName'), (_class = class HeadDataService extends _headData.default {
    get config() {
      return _emberGetConfig.default['blog'];
    }

    get siteName() {
      return this.config.title;
    }

    get currentController() {
      return (0, _application.getOwner)(this).lookup(`controller:${this.routeName}`);
    }

    get currentRouteMeta() {
      var _this$currentControll;

      return (_this$currentControll = this.currentController.model.post) !== null && _this$currentControll !== void 0 ? _this$currentControll : this.currentController.model;
    }

    get title() {
      if (this.routeName === 'tag') {
        return `Tag: ${this.currentRouteMeta.name}`;
      }

      if (this.routeName === 'author') {
        return `Author: ${this.currentRouteMeta.name}`;
      }

      return super.title;
    }

    get description() {
      let currentModel = this.currentRouteMeta;

      if (currentModel && currentModel.html) {
        const excerpt = (0, _excerpt.getExcerpt)(currentModel.html, {
          words: 33
        });
        return `${excerpt}${excerpt.length !== (0, _excerpt.stripHTML)(currentModel.html).length ? '...' : ''}`;
      }

      return blog.description;
    }

    get slug() {
      var _this$currentRouteMet;

      return (_this$currentRouteMet = this.currentRouteMeta) === null || _this$currentRouteMet === void 0 ? void 0 : _this$currentRouteMet.id;
    }

    get categories() {
      var _this$currentRouteMet2, _this$currentRouteMet3;

      return (_this$currentRouteMet2 = this.currentRouteMeta) === null || _this$currentRouteMet2 === void 0 ? void 0 : (_this$currentRouteMet3 = _this$currentRouteMet2.tags) === null || _this$currentRouteMet3 === void 0 ? void 0 : _this$currentRouteMet3.mapBy('name');
    }

    get imgSrc() {
      let url = blog.host ? `${blog.host}` : '';
      url += this.currentRouteMeta.image || blog.rssLogo || blog.logo;
      return url;
    }

    get url() {
      // url is only ever valid if you have a host
      if (!blog.host) {
        return null;
      } // we remove any trailing / from the host and add it back in to make sure
      // that we always have a consistent URL


      const normalisedHost = blog.host.replace(/\/$/, '');
      const normalisedUrl = this.router.currentURL.replace(/\/$/, '');
      return `${normalisedHost}${normalisedUrl}/`;
    }

    get type() {
      if (this.routeName === 'post') {
        return 'article';
      }

      return 'website';
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "siteName", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "siteName"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "currentController", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "currentController"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "currentRouteMeta", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "currentRouteMeta"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "title", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "title"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "description", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "description"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "slug", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "slug"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "categories", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "categories"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "imgSrc", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "imgSrc"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "url", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "url"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "type", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "type"), _class.prototype)), _class));
  _exports.default = HeadDataService;
});