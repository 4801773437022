define("empress-blog/routes/index", ["exports", "@ember/routing/route", "@ember/service"], function (_exports, _route, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend({
    queryParams: {
      page: {
        refreshModel: true
      }
    },
    classNames: ["index-template", "home-template"],
    store: (0, _service.inject)(),
    blog: (0, _service.inject)(),

    model(params) {
      if (this.blog.paginate) {
        return this.store.query('content', params);
      }

      return this.store.findAll('content');
    }

  });

  _exports.default = _default;
});