define("empress-blog/models/page", ["exports", "empress-blog/models/content"], function (_exports, _content) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class PageModel extends _content.default {}

  _exports.default = PageModel;
});