define("empress-blog/services/blog", ["exports", "@ember/service", "@ember/object", "ember-get-config"], function (_exports, _service, _object, _emberGetConfig) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function configParam(param) {
    return (0, _object.computed)(function () {
      return (0, _object.get)(_emberGetConfig.default, `blog.${param}`);
    });
  }

  var _default = _service.default.extend({
    title: configParam('title'),
    description: configParam('description'),
    logo: configParam('logo'),
    coverImage: configParam('coverImage'),
    coverMeta: configParam('coverMeta'),
    navigation: configParam('navigation'),
    twitter: configParam('twitter'),
    facebook: configParam('facebook'),
    host: configParam('host'),
    paginate: configParam('paginate')
  });

  _exports.default = _default;
});