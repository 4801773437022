define("empress-blog/routes/post", ["exports", "@ember/routing/route", "rsvp"], function (_exports, _route, _rsvp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend({
    classNames: ["post-template"],

    model(params) {
      // load authors first for ember-data autopopulation
      return this.store.findAll('author').then(() => {
        return (0, _rsvp.hash)({
          post: this.store.findRecord('content', params.id),
          posts: this.store.findAll('content')
        });
      });
    }

  });

  _exports.default = _default;
});