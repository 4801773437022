define("empress-blog/routes/page", ["exports", "@ember/routing/route"], function (_exports, _route) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend({
    classNames: ["page-template"],

    model(params) {
      return this.store.findRecord('page', params.id);
    }

  });

  _exports.default = _default;
});