define("empress-blog/controllers/application", ["exports", "@ember/controller", "@ember/service", "@ember/object"], function (_exports, _controller, _service, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _controller.default.extend({
    blog: (0, _service.inject)(),
    now: (0, _object.computed)(function () {
      return new Date();
    })
  });

  _exports.default = _default;
});