define("ember-scroll/instance-initializers/ember-scroll", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize(appInstance) {
    if (window && window.document) {
      const router = appInstance.lookup('service:router');
      const scroll = appInstance.lookup('service:scroll');
      router.on('routeWillChange', scroll.routeWillChange);
      router.on('routeDidChange', scroll.routeDidChange);
    }
  }

  var _default = {
    initialize
  };
  _exports.default = _default;
});