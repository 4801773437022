define("ember-meta/services/head-data", ["exports", "@ember/service", "@ember/object", "@ember/object/computed", "@ember/application", "ember-get-config"], function (_exports, _service, _object, _computed, _application, _emberGetConfig) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let HeadDataService = (_dec = (0, _computed.alias)('router.currentRouteName'), _dec2 = (0, _object.computed)('routeName'), _dec3 = (0, _computed.reads)('currentRouteMeta.articleTitle'), _dec4 = (0, _computed.reads)('currentRouteMeta.author'), _dec5 = (0, _computed.reads)('currentRouteMeta.canonical'), _dec6 = (0, _computed.reads)('currentRouteMeta.categories'), _dec7 = (0, _computed.reads)('currentRouteMeta.content'), _dec8 = (0, _computed.reads)('currentRouteMeta.date'), _dec9 = (0, _object.computed)('content', 'currentRouteMeta.description', 'routeName', 'config.description'), _dec10 = (0, _object.computed)('currentRouteMeta.imgSrc', 'routeName', 'config.imgSrc'), _dec11 = (0, _object.computed)('currentRouteMeta.jsonld', 'routeName'), _dec12 = (0, _object.computed)('categories', 'routeName'), _dec13 = (0, _object.computed)('currentRouteMeta.siteName', 'routeName', 'config.siteName'), _dec14 = (0, _computed.reads)('currentRouteMeta.slug'), _dec15 = (0, _computed.reads)('categories'), _dec16 = (0, _object.computed)('currentRouteMeta.title', 'routeName', 'config.title'), _dec17 = (0, _object.computed)('currentRouteMeta.twitterUsername', 'routeName', 'config.twitterUsername'), _dec18 = (0, _object.computed)('currentRouteMeta.type', 'routeName'), _dec19 = (0, _object.computed)('currentRouteMeta.url', 'routeName', 'slug', 'config.url'), (_class = class HeadDataService extends _service.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "router", _descriptor, this);

      _initializerDefineProperty(this, "routeName", _descriptor2, this);

      _initializerDefineProperty(this, "articleTitle", _descriptor3, this);

      _initializerDefineProperty(this, "author", _descriptor4, this);

      _initializerDefineProperty(this, "canonical", _descriptor5, this);

      _initializerDefineProperty(this, "categories", _descriptor6, this);

      _initializerDefineProperty(this, "content", _descriptor7, this);

      _initializerDefineProperty(this, "date", _descriptor8, this);

      _initializerDefineProperty(this, "slug", _descriptor9, this);

      _initializerDefineProperty(this, "tags", _descriptor10, this);
    }

    get config() {
      return _emberGetConfig.default['ember-meta'];
    }

    get currentRouteMeta() {
      const currentRoute = (0, _application.getOwner)(this).lookup(`route:${this.routeName}`);
      return currentRoute.metaInfo || currentRoute.currentModel;
    }
    /**
     * Used for og:title, twitter:title as the title to show in the unfurled links
     */


    /**
     * Used for <meta name="description">, og:description, twitter:description
     * This is the main content of your page, shown as the content in the unfurled links
     * If you pass a description, it will be used, otherwise it will truncate your content,
     * and finally it will use the description from the global config.
     */
    get description() {
      var _this$currentRouteMet;

      const description = (_this$currentRouteMet = this.currentRouteMeta) === null || _this$currentRouteMet === void 0 ? void 0 : _this$currentRouteMet.description;
      const content = this.content;

      if (description) {
        return description;
      } else if (content && content.substring) {
        return `${content.substring(0, 260)}...`;
      }

      return this.config.description;
    }
    /**
     * Used for og:image twitter:image:src, the image to display in your unfurled links
     */


    get imgSrc() {
      var _this$currentRouteMet2, _this$currentRouteMet3;

      return (_this$currentRouteMet2 = (_this$currentRouteMet3 = this.currentRouteMeta) === null || _this$currentRouteMet3 === void 0 ? void 0 : _this$currentRouteMet3.imgSrc) !== null && _this$currentRouteMet2 !== void 0 ? _this$currentRouteMet2 : this.config.imgSrc;
    }

    get jsonld() {
      var _this$currentRouteMet4;

      const jsonld = (_this$currentRouteMet4 = this.currentRouteMeta) === null || _this$currentRouteMet4 === void 0 ? void 0 : _this$currentRouteMet4.jsonld;

      if (jsonld) {
        return JSON.stringify(jsonld);
      }

      return false;
    }
    /**
     * Used for twitter meta to display 'filed under'
     */


    get keywords() {
      const categories = this.categories;
      return categories ? categories.join(', ') : null;
    }
    /**
     * Used for og:site_name
     */


    get siteName() {
      var _this$currentRouteMet5, _this$currentRouteMet6;

      return (_this$currentRouteMet5 = (_this$currentRouteMet6 = this.currentRouteMeta) === null || _this$currentRouteMet6 === void 0 ? void 0 : _this$currentRouteMet6.siteName) !== null && _this$currentRouteMet5 !== void 0 ? _this$currentRouteMet5 : this.config.siteName;
    }
    /**
     * Internal - used for url
     */


    /**
     * Used for <title>, og:title, twitter:title
     */
    get title() {
      var _this$currentRouteMet7, _this$currentRouteMet8;

      return (_this$currentRouteMet7 = (_this$currentRouteMet8 = this.currentRouteMeta) === null || _this$currentRouteMet8 === void 0 ? void 0 : _this$currentRouteMet8.title) !== null && _this$currentRouteMet7 !== void 0 ? _this$currentRouteMet7 : this.config.title;
    }
    /**
     * Used for twitter:site and twitter:creator
     */


    get twitterUsername() {
      var _this$currentRouteMet9, _this$currentRouteMet10;

      return (_this$currentRouteMet9 = (_this$currentRouteMet10 = this.currentRouteMeta) === null || _this$currentRouteMet10 === void 0 ? void 0 : _this$currentRouteMet10.twitterUsername) !== null && _this$currentRouteMet9 !== void 0 ? _this$currentRouteMet9 : this.config.twitterUsername;
    }
    /**
     * Used for og:type, defaults to 'website'
     */


    get type() {
      var _this$currentRouteMet11, _this$currentRouteMet12;

      return (_this$currentRouteMet11 = (_this$currentRouteMet12 = this.currentRouteMeta) === null || _this$currentRouteMet12 === void 0 ? void 0 : _this$currentRouteMet12.type) !== null && _this$currentRouteMet11 !== void 0 ? _this$currentRouteMet11 : 'website';
    }
    /**
     * Used for <link rel="canonical">, og:url, twitter:url
     */


    get url() {
      var _this$currentRouteMet13, _this$currentRouteMet14;

      let url = (_this$currentRouteMet13 = (_this$currentRouteMet14 = this.currentRouteMeta) === null || _this$currentRouteMet14 === void 0 ? void 0 : _this$currentRouteMet14.url) !== null && _this$currentRouteMet13 !== void 0 ? _this$currentRouteMet13 : this.config.url;
      const slug = this.slug;

      if (slug) {
        url = `${url}${slug}/`;
      }

      return url;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "routeName", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "currentRouteMeta", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "currentRouteMeta"), _class.prototype), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "articleTitle", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "author", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "canonical", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "categories", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "content", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "date", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "description", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "description"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "imgSrc", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "imgSrc"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "jsonld", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "jsonld"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "keywords", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "keywords"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "siteName", [_dec13], Object.getOwnPropertyDescriptor(_class.prototype, "siteName"), _class.prototype), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "slug", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "tags", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "title", [_dec16], Object.getOwnPropertyDescriptor(_class.prototype, "title"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "twitterUsername", [_dec17], Object.getOwnPropertyDescriptor(_class.prototype, "twitterUsername"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "type", [_dec18], Object.getOwnPropertyDescriptor(_class.prototype, "type"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "url", [_dec19], Object.getOwnPropertyDescriptor(_class.prototype, "url"), _class.prototype)), _class));
  _exports.default = HeadDataService;
});