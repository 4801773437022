define("empress-blog/helpers/excerpt", ["exports", "@ember/component/helper", "@ember/template", "@ember/utils", "downsize-cjs"], function (_exports, _helper, _template, _utils, _downsizeCjs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getExcerpt = getExcerpt;
  _exports.stripHTML = stripHTML;
  _exports.excerpt = excerpt;
  _exports.default = void 0;

  function getExcerpt(html, truncateOptions) {
    truncateOptions = truncateOptions || {}; // Strip inline and bottom footnotes

    const excerpt = stripHTML(html);

    if (!truncateOptions.words && !truncateOptions.characters) {
      truncateOptions.words = 50;
    }

    return (0, _downsizeCjs.default)(excerpt, truncateOptions);
  }

  function stripHTML(html) {
    let strippedHTML = html.replace(/<a href="#fn.*?rel="footnote">.*?<\/a>/gi, '');
    strippedHTML = strippedHTML.replace(/<div class="footnotes"><ol>.*?<\/ol><\/div>/, ''); // Strip other html

    strippedHTML = strippedHTML.replace(/<\/?[^>]+>/gi, '');
    strippedHTML = strippedHTML.replace(/(\r\n|\n|\r)+/gm, ' ');
    return strippedHTML;
  }

  function excerpt(content, options
  /*, hash*/
  ) {
    let truncateOptions = options || {};
    const excerptText = options.custom_excerpt ? String(options.custom_excerpt) : String(content);
    let {
      words,
      characters
    } = truncateOptions;
    truncateOptions = {
      words,
      characters
    };
    Object.keys(truncateOptions).map(function (key) {
      truncateOptions[key] = parseInt(truncateOptions[key], 10);
    });

    if (!(0, _utils.isEmpty)(options.custom_excerpt)) {
      truncateOptions.characters = options.custom_excerpt.length;

      if (truncateOptions.words) {
        delete truncateOptions.words;
      }
    }

    return (0, _template.htmlSafe)(getExcerpt(excerptText, truncateOptions));
  }

  var _default = (0, _helper.helper)(excerpt);

  _exports.default = _default;
});