define("@embroider/router/index", ["exports", "@ember/routing/router", "@ember/test-waiters"], function (_exports, _router, _testWaiters) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /*
    This code is adapted from ember-engines/addon/-private/router-ext.js.
  */
  let Router;
  {
    Router = _router.default;
  }
  var _default = Router;
  _exports.default = _default;
});