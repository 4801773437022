define("empress-blog/controllers/tag", ["exports", "@ember/controller", "@ember/object", "@ember/service"], function (_exports, _controller, _object, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* eslint-disable ember/require-computed-macros */
  var _default = _controller.default.extend({
    blog: (0, _service.inject)(),
    coverImage: (0, _object.computed)('model.image', 'blog.coverImage', function () {
      return this.model.image || this.blog.coverImage;
    })
  });

  _exports.default = _default;
});