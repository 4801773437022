define("empress-blog/services/url", ["exports", "@ember/service", "@ember/object", "ember-get-config"], function (_exports, _service, _object, _emberGetConfig) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _service.default.extend({
    prefix: (0, _object.computed)(function () {
      let prefix = _emberGetConfig.default.apiHost || '';

      if (prefix && _emberGetConfig.default.apiNamespace) {
        prefix += `/${_emberGetConfig.default.apiNamespace}`;
      } else if (prefix) {
        prefix += _emberGetConfig.default.apiNamespace;
      }

      return prefix;
    })
  });

  _exports.default = _default;
});