define("empress-blog/routes/application", ["exports", "@ember/routing/route", "rsvp"], function (_exports, _route, _rsvp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend({
    model() {
      return _rsvp.default.hash({
        authors: this.store.findAll('author'),
        tags: this.store.findAll('tag')
      });
    }

  });

  _exports.default = _default;
});