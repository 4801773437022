define("empress-blog/controllers/post", ["exports", "@ember/controller", "@ember/object", "@ember/service", "@ember/object/computed"], function (_exports, _controller, _object, _service, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* eslint-disable ember/require-computed-property-dependencies, ember/no-get, ember/require-return-from-computed */
  var _default = _controller.default.extend({
    blog: (0, _service.inject)(),
    url: (0, _service.inject)(),

    init() {
      this._super(...arguments);

      this.postSorting = ['date:desc'];
    },

    sortedPosts: (0, _computed.sort)('model.posts', 'postSorting'),
    relatedPosts: (0, _object.computed)('model.post.id', 'sortedPosts', function () {
      return this.sortedPosts.filter(post => {
        if ((0, _object.get)(post, 'id') === (0, _object.get)(this, 'model.post.id')) {
          return false;
        }

        return (0, _object.get)(post, 'primaryTag') === (0, _object.get)(this, 'model.post.primaryTag');
      });
    }),
    prevPost: (0, _object.computed)('model.post.id', 'sortedPosts', function () {
      let index = this.sortedPosts.indexOf((0, _object.get)(this, 'model.post'));

      if (index > 0) {
        return this.sortedPosts.get(index - 1);
      }
    }),
    nextPost: (0, _object.computed)('model.post.id', 'sortedPosts', function () {
      let index = this.sortedPosts.indexOf((0, _object.get)(this, 'model.post'));

      if (index < (0, _object.get)(this, 'sortedPosts.length') - 1) {
        return this.sortedPosts.get(index + 1);
      }
    }),
    tagBackgroundImageStyle: (0, _object.computed)(function () {
      if ((0, _object.get)(this, 'model.post.primaryTag.image')) {
        return `background-image: url(${(0, _object.get)(this, 'url.prefix')}${(0, _object.get)(this, 'model.post.primaryTag.image')})`;
      } else if ((0, _object.get)(this, 'blog.coverImage')) {
        return `background-image: url(${(0, _object.get)(this, 'url.prefix')}${(0, _object.get)(this, 'blog.coverImage')})`;
      }
    })
  });

  _exports.default = _default;
});