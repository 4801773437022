define("empress-blog/adapters/application", ["exports", "@ember-data/adapter/json-api", "@ember/object", "@ember/service"], function (_exports, _jsonApi, _object, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* eslint-disable ember/require-computed-property-dependencies, ember/no-get */
  var _default = _jsonApi.default.extend({
    fastboot: (0, _service.inject)(),
    host: (0, _object.computed)('fastboot.isFastBoot', function () {
      if (this.get('fastboot.isFastBoot')) {
        let protocol = this.get('fastboot.request.protocol');
        return `${protocol}//${this.get('fastboot.request.host')}`;
      } else {
        return window.location.origin;
      }
    }),

    ajaxOptions(url, type, options) {
      // remove the query params
      delete options.data;
      return this._super(url, type, options);
    },

    urlForFindAll(modelName) {
      return `${this.host}/${modelName}/${modelName}.json`;
    },

    urlForFindRecord(id, modelName) {
      return `${this.host}/${modelName}/${id}.json`;
    },

    // query is only ever used for pagination
    urlForQuery(query) {
      return `${this.host}${query.page}`;
    }

  });

  _exports.default = _default;
});